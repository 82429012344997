import logo from './icon.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <br/>
        <a
          className="App-link"
          href="https://blog.alkattan.eu"
          target="_blank"
          rel="noopener noreferrer"
        >
          Blog
        </a>
        <a
          className="App-link"
          href="https://howtolinux.alkattan.eu"
          target="_blank"
          rel="noopener noreferrer"
        >
          Linux howto
        </a>
        <a
          className="App-link"
          href="https://jareblinux.alkattan.eu"
          target="_blank"
          rel="noopener noreferrer"
        >
          جرب لينكس
        </a>
        <a
          className="App-link"
          href="https://arabicfonts.alkattan.eu"
          target="_blank"
          rel="noopener noreferrer"
        >
          الخطوط العربية
        </a>
        <a
          className="App-link"
          href="https://base64.alkattan.eu"
          target="_blank"
          rel="noopener noreferrer"
        >
          Encode and decode base64
        </a>
        <a
          className="App-link"
          href="https://derdiedas.alkattan.eu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Der Die Das App
        </a>
        <a
          className="App-link"
          href="https://regex.alkattan.eu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Regex Tester
        </a>
      </header>
    </div>
  );
}

export default App;
